.About{

    height: 1000px;
    margin: 10%;
    text-align: center;
}
.section{
    height: 100vh;
    opacity: .1;
    width: 50%;
    background: #3e64ff;
}
.subheading{

    text-transform: uppercase;
    font-size: 16px;
    font-weight: 800;
    color: #3e64ff;
    letter-spacing: 4px;
}
h1{
    margin-top:0;
    margin-bottom:0.5rem;

    font-weight:500;line-height:1.2;
    font-family: 'Poppins', sans-serif;
}
.Overlay{
    width: 50%;
    background: #3e64ff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    opacity: .1;

}
.Intro{
    position: absolute;
    top: 44%;
    width: 100%;
}
h2{
    font-family: 'Poppins', sans-serif;
}

h2{margin-top:0;margin-bottom:0.5rem;}
h2{margin-bottom:0.5rem;font-weight:500;line-height:1.2;}
h2{font-size:2rem;}
h2 span{
    color:#3e64ff;text-decoration:underline;
    border-right: 3px solid #444;
    padding-right: 3px;
}