.intro-Info{
    padding-top: 0%;
}

#aboutMe{
    text-align: left;
    padding-top: 82px;
}

.intro-sec{
    font-family: system-ui;
    font-size: 16px;
    padding-top: 16px;
    font-weight: 300;
    line-height: 24px;
}

.about-info{
    list-style: none;
    padding-left: 0;
    margin-bottom: 48px;
}

.about-info li{
    margin: 16px 0;
}

.info-section .btn-container{
    margin-top: 22px;
}

.field{
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;

}
.answer{
    font-family: system-ui;
    font-size: 1.20rem;
    display: flex;
}

@media only screen and (max-width: 767px) {
    .intro-Info{
        padding: 56px 24px 0 !important;
    }
    
    #aboutMe{
        padding-top: 0;
    }
}