
.ftco-footer{
    font-size: 16px;
    background: #000000;
    padding: 32px 16px;
    z-index: 0;
}

.ftco-footer h4{
    color: white;
}

.icons-container {
    display: flex;
    justify-content:space-between;
}

.Icons{
    display: inline;
    margin-right: 16px;
}